import * as React from "react";
import ShareIcon from "../../../Assets/Sharing-Social Media/SVG/share-icon.svg";
import "./iconButton.style.css";

interface IProps {
  className?: string;
  icon: "share";
  onClick: () => void;
}

const getIcon = (icon: string) => {
  if (icon === "share") {
    return ShareIcon;
  }
  return ShareIcon;
};

const IconButton = (props: IProps) => {
  const { className, icon, onClick } = props;
  return (
    <img
      src={getIcon(icon)}
      role="presentation"
      alt=""
      className={`iconButton ${icon} ${className || ""}`}
      onClick={onClick}
    />
  );
};

export default IconButton;
