import { Entry } from "contentful";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SearchInput } from "../../components/Form";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import { FavouritesSVG, SearchSVG } from "../../components/SVG";
import { INavigation } from "../../contentfulTypes";
import { AppContext, IAppInterface } from "../../context";
import { getCachedQuery } from "../../util/contentfulQueries";
import customGA from "../../util/customGA";
import DisableScroll from "../DisableScroll";
import "./navigation.style.css";
import NavigationLeft from "./NavigationLeft";
import NavigationRight from "./NavigationRight";
import MapSVG from "../../components/SVG/Map/Map";

interface IProps extends WithTranslation {
  history: any;
  activeLocation: string;
  location: any;
}

interface INavigationState {
  data: Entry<INavigation> | null;
  navigation: null | INavigation;
  isSearchOpen: boolean;
  searchValue: string;
  isNavigationOpen: boolean;
  scrollTop: boolean;
  yOffset: number;
  isLoaded: boolean;
  activeLocation: string | null;
  activeLocationNoLn: string | null;
}

class Navigation extends React.Component<IProps, INavigationState> {
  public menuRef: any;
  private responsiveContainer: React.RefObject<HTMLInputElement>;

  public constructor(props: any) {
    super(props);
    this.state = {
      data: null,
      navigation: null,
      isSearchOpen: false,
      searchValue: "",
      isNavigationOpen: false,
      scrollTop: true,
      yOffset: 0,
      isLoaded: false,
      activeLocation: this.props.activeLocation,
      activeLocationNoLn: this.props.activeLocation.substring(3),
    };

    this.responsiveContainer = React.createRef();
    this.onScrollMount = this.onScrollMount.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchKeyDown = this.handleSearchKeyDown.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.menuRef = React.createRef();

    this.getData(props.i18n.language);
  }

  public componentWillMount() {
    window.addEventListener("scroll", this.onScrollMount);
  }

  public componentWillUnmount() {
    window.removeEventListener("scroll", this.onScrollMount);
  }

  // Needed, because after language switch this component stays mounted and
  // data needs to be refetched in the correct language.
  public componentDidUpdate() {
    if (
      !this.state.data ||
      this.state.data.sys.locale !== this.props.i18n.language
    ) {
      this.getData(this.props.i18n.language);
    }

    if (this.state.activeLocation !== this.props.activeLocation) {
      const activeSlug = this.props.activeLocation.substring(3);
      this.setState({
        activeLocation: this.props.activeLocation,
        activeLocationNoLn: activeSlug,
        scrollTop: activeSlug === "/article/travel-updates" ? false : true,
      });
    }
  }

  public componentDidMount() {
    if (this.state.activeLocationNoLn === "/article/travel-updates") {
      this.setState({
        scrollTop: false,
      });
    }
  }

  public getData = async (language: string) => {
    const data = await getCachedQuery<INavigation>(
      {
        content_type: "navigation",
        limit: 1,
        include: 2,
      },
      language
    );
    if (data && data.items.length > 0) {
      this.setState({ data: data.items[0] });
    }
  };

  public handleHomePageClick() {
    this.closeNavigation();
    this.props.history.push("/");
  }

  public closeNavigation() {
    this.setState({
      yOffset:
        !this.state.isNavigationOpen && window.innerWidth < 1150
          ? 0
          : window.pageYOffset <= 40
          ? 0 - window.pageYOffset
          : 0,
      isNavigationOpen: false,
    });
  }

  // Toggle the menu open/closed
  public toggleNavigation() {
    // Fix for scrolling issue on mobile sub menu (css alternative not found)
    if (this.responsiveContainer && this.responsiveContainer.current) {
      this.responsiveContainer.current.scrollTo(0, 0);
    }
    this.setState({
      isNavigationOpen: !this.state.isNavigationOpen,
    });
  }

  public openSearch() {
    if (window.innerWidth < 1150) {
      this.setState({ isSearchOpen: !this.state.isSearchOpen });
      this.closeNavigation();
      return true;
      const menuBtn: HTMLElement = document.getElementById("toggleNavigation")!;
      const logo: HTMLElement = document.getElementById("nav__logo")!;
      const searchBar: HTMLElement = document.getElementById("nav__searchBar")!;
      const searchWidth = window.innerWidth - (18 + 25 + 9 + 20 + 9 + 50);
      if (!this.state.isSearchOpen) {
        searchBar.style.width = searchWidth + "px";
        searchBar.style.overflow = "unset";
        menuBtn.style.opacity = "0";
        logo.style.opacity = "0";
      } else {
        searchBar.style.width = "0px";
        searchBar.style.overflow = "hidden";
        menuBtn.style.opacity = "1";
        logo.style.opacity = "1";
      }
      this.setState({ isSearchOpen: !this.state.isSearchOpen });
      return true;
    } else if (document.getElementById("nav__right")) {
      const menuWhole: HTMLElement = document.getElementById("nav__right")!;
      const searchBar: HTMLElement = document.getElementById("nav__searchBar")!;
      const langDiv: HTMLElement = document.getElementById("nav__language")!;
      const mapDiv: HTMLElement = document.getElementById("nav__map")!;
      const menuChildren = menuWhole.children;
      const menuEls = Array.from(menuChildren);
      if (!this.state.isSearchOpen) {
        let menuWidth = 0;
        menuEls.forEach((item, index) => {
          const el = item as HTMLElement;
          const elStyle = window.getComputedStyle(el);
          const marginRight = parseFloat(elStyle.marginRight);
          menuWidth += el.offsetWidth + marginRight;
          el.style.opacity = "0";
        });
        menuWidth +=
          langDiv.offsetWidth +
          parseFloat(window.getComputedStyle(langDiv).marginRight);
        langDiv.style.opacity = "0";
        menuWidth +=
          parseFloat(window.getComputedStyle(mapDiv).width) +
          parseFloat(window.getComputedStyle(mapDiv).marginRight);
        mapDiv.style.opacity = "0";
        searchBar.style.width = menuWidth + 25 + 9 + "px";
        searchBar.style.overflow = "unset";
      } else {
        menuEls.forEach((item, index) => {
          const el = item as HTMLElement;
          el.style.opacity = "1";
        });
        langDiv.style.opacity = "1";
        mapDiv.style.opacity = "1";
        searchBar.style.width = "0px";
        searchBar.style.overflow = "hidden";
      }
      this.setState({ isSearchOpen: !this.state.isSearchOpen });
      return true;
    }
    return false;
  }

  public handleSearchChange(evt: any) {
    this.setState({ searchValue: evt.target.value });
  }

  public handleSearchKeyDown(evt: any) {
    if (evt.key === "Enter") {
      this.handleSearchSubmit();
    }
  }

  public handleSearchSubmit() {
    customGA("event", "search", {
      search_term: this.state.searchValue,
    });
    this.setState({
      searchValue: "",
    });
    this.openSearch();
    this.props.history.push(
      `/${this.props.i18n.language}/questions/search?q=${this.state.searchValue}&back=${this.state.activeLocation}`
    );
  }

  public render() {
    const data = this.state.data;
    if (!data) {
      return null;
    }
    const mystyles: React.CSSProperties = {
      top: this.state.yOffset + "px",
    };

    return (
      <AppContext.Consumer>
        {({ navigationType, setFavourites, favouritesList }: IAppInterface) => (
          <DisableScroll
            disable={this.state.isNavigationOpen}
            allow={this.menuRef.current}
          >
            <nav
              className={`nav 
                ${
                  false && this.state.scrollTop ? "-scroll-top" : "-scroll-down"
                }
                -type-${false ? navigationType : "1"}
                ${this.state.isNavigationOpen ? "-open" : "-closed"}
                aria-label="main navigation"`}
              style={mystyles}
            >
              <div className="nav__top" />
              <button
                className={`nav__menu-hamburger`}
                onClick={() => this.toggleNavigation()}
                id="toggleNavigation"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <div className="nav__menu-wrapper">
                <div className="nav__menu" ref={this.menuRef}>
                  <div className="nav__searchBar -mobile">
                    <div className="nav__searchBar-inside">
                      <SearchInput
                        className="nav__searchInput"
                        name="nav search"
                        value={this.state.searchValue}
                        placeholder={this.props.i18n.t("searchSomething")}
                        onKeyDown={this.handleSearchKeyDown}
                        onChange={this.handleSearchChange}
                        onClose={() => {
                          this.setState({ searchValue: "" });
                          this.openSearch();
                        }}
                      />
                    </div>
                  </div>
                  <NavigationLeft
                    items={data.fields.leftLinks}
                    onClick={() => this.closeNavigation()}
                  />
                  <NavigationRight
                    items={data.fields.rightLinks}
                    onClick={() => this.closeNavigation()}
                  />
                  <LanguageSwitcher
                    history={this.props.history}
                    className="footer__language footer__language-mobile"
                    buttonId="nav__language-button"
                    divId="nav__language"
                    mobile={true}
                  />
                </div>
              </div>
              <div
                role="button"
                className="nav__logo"
                onClick={() => this.handleHomePageClick()}
                id="nav__logo"
              />
              <div className="nav__searchBar -desktop" id="nav__searchBar">
                <div className="nav__searchBar-inside">
                  <SearchInput
                    className="nav__searchInput"
                    name="nav search"
                    value={this.state.searchValue}
                    placeholder={this.props.i18n.t("searchSomething")}
                    onKeyDown={this.handleSearchKeyDown}
                    onChange={this.handleSearchChange}
                    onClose={() => {
                      this.setState({ searchValue: "" });
                      this.openSearch();
                    }}
                  />
                </div>
              </div>
              <LanguageSwitcher
                history={this.props.history}
                buttonId="nav__language-button"
                divId="nav__language"
                mobile={false}
              />
              <MapSVG
                className="nav__map"
                onClick={() =>
                  window.open("https://map.curacao.com/", "_blank", "noopener")
                }
                style={"grey"}
              />
              <SearchSVG
                className="nav__search"
                onClick={
                  this.state.isSearchOpen
                    ? () => this.handleSearchSubmit()
                    : () => this.openSearch()
                }
                style={"grey"}
              />
              <FavouritesSVG
                className="nav__favourites"
                onClick={() => {
                  setFavourites(true);
                  this.setState({ isNavigationOpen: false });
                }}
                style={favouritesList.length === 0 ? "grey" : "active"}
              />
            </nav>
          </DisableScroll>
        )}
      </AppContext.Consumer>
    );
  }

  private onScrollMount() {
    const isScrollTop =
      window.pageYOffset <= 0 &&
      this.state.activeLocationNoLn !== "/article/travel-updates";
    this.setState({
      yOffset: 0,
    });
    if (this.state.scrollTop !== isScrollTop) {
      this.setState({ scrollTop: isScrollTop });
    }
  }
}

export default withTranslation()(Navigation);
